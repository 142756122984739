.standalone {
  height: 100%;
  padding: 0 16px;
}

.standalone__logo {
  margin: 0 auto 16px;
  padding-top: 32px;
  max-width: 300px;

  img {
    width: 100%;
    height: auto;
  }
}

.standalone__content {
  max-width: breakpoint('sm.min');
  margin: 0 auto 32px;
}

.standalone__content--sm {
  max-width: 400px;
}

.standalone__title {
  text-align: center;

  @media (max-width: breakpoint('xs.max')) {
    margin-bottom: 8px;
  }
}

.standalone__error {
  margin: 8px 0;
}
