
@use '@angular/material' as mat;

mat-dialog-container.mat-mdc-dialog-container {
  border-radius: $card-border-radius;
  max-width: 100%;

  .mdc-dialog__title {
    padding: 0 16px 16px;
    margin: 0;
    font-family: $heading-font-family
  }

  .mat-dialog-title--icons {
    padding-top: 16px;
  }

  .mat-dialog-title--actions {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .mat-mdc-dialog-content {
    font-size: mat.m2-font-size($wise-typography, 'body-1');
    padding: 0 16px;
    color: inherit;
    font-weight: 400;

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }

  .mat-mdc-dialog-title+.mat-mdc-dialog-content.dialog-content-scroll {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mat-mdc-dialog-actions {
    padding: 16px;
  }
}

.dialog-sm {
  width: breakpoint('sm.min');
}

.dialog-md {
  width: breakpoint('md.min');
}

.dialog-lg {
  width: breakpoint('lg.min');
}

.dialog-xl {
  width: breakpoint('xl.min');
}

.cdk-overlay-pane {
  max-width: 90vw !important;

  @media (max-width: breakpoint('xs.max')) {
    max-width: 95vw !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow-x: hidden;
}
