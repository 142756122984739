@use '@angular/material' as mat;

.button--progress.mdc-button {
  .mdc-button__label {
    position: unset;
  }

  mat-progress-bar {
      position: absolute;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
  }

  .mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-height: 4px;
    --mdc-linear-progress-track-height: 4px;
  }
}

.mat-mdc-fab.mat-fab--extended {
  border-radius: 24px;
  padding: 0px 16px;
  width: auto;
  height: 48px;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper {
    padding: 8px 0;
  }
}

.button--card {
  cursor: pointer;

  &:hover, &:focus {
    outline: none;
    @include mat.elevation(8);
  }
}

.button--social-login {
  .mdc-button__label {
    display: flex;
    align-items: center;
  }

  img {
    width: 16px;
    height: auto;
    margin-left: -16px;
    padding: 8px;
  }
}

.button--google {
  background-color: #1a73e8 !important;
  text-transform: none !important;

  img {
    background-color: #ffffff;
    border-radius: 2px;
    margin: 0 16px 0 -14px;
  }
}

.button--microsoft {
  background-color: #2f2f2f !important;
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-radius: 0 !important;

  img {
    margin: 0 4px 0 -12px;
  }
}
